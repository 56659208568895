<template>
<div>
    <div class="breadcrumb_header">
        <div class="breadcrumb">
        <h4 style="margin:0;padding-left: 10px;">NeXa</h4>
        <span class="divider"></span>
        <span>Analyze Pleading</span>
        </div>
    <div class="open_sidebar" @click="toggleSidebar">
      <span class="material-symbols-rounded icon">keyboard_double_arrow_left</span>
      <p>Open Sidebar</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-1 mt-3">
        <div class="d-flex justify-content-start cursor-pointer back" @click="goBack">
      <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
      <span style="margin: 0 8px;">Back</span>
    </div>
    </div>
    <div class="col-md-10 mt-5"> 
        <main>
            <section class="uploads">
                <h5>Analyze Pleading</h5>
                <div class="mt-3">
                <h4>Upload Pleadings for Analyze</h4>
                <span class="sub-title mb-2">Your files will be securely stored in Nexa, accessible only to you and your workspace admins, and you can delete them at any time.</span>
                </div>
                <div class="d-flex mb-3 mt-3">
                    <div style="width:50%;" class="me-2">
                        <FileInput
                        style="flex-grow: 1;"
                        :accept="['pdf']"
                        @input-files="$set(files, 0, $event)"
                    />
                    </div>
                    <div>
                        <div v-if="files.length > 0 && files[0].length > 0" style="width:400px;">
                            <FileProcess
                            style="width: 100%; max-height: 200px; overflow-y: auto;"
                            :accept="['pdf']"
                            :files="files[0]"
                            @output-files="$set(files, 1, $event)"
                        />
                        </div>
                        <div v-else class="right-container">
                            <div>
                            <p >Upload one or more particulars of claim and/or defenses to begin.</p>
                        </div>
                        </div>
                    </div>
                 </div>
                    <div class="d-flex mt-2 swift">
                    <div class="toggle me-2">
                        <div class="switch"><div class="button"></div></div>
                    </div>
                        Organize files into folders
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <div class="upload" @click="uploadFile">
                        <span class="material-symbols-rounded icon">arrow_forward</span>
                        Next</div>
                      </div>
                </section>    
        </main> 
    </div>
    <div class="col-md-1">
    </div>
    </div>
</div>
</template>
<script>
import FileInput from './../../components/input/FileInput.vue';
import FileProcess from './../../components/input/FileProcess.vue';
export default {
    components: {
    FileInput, FileProcess
  },
    data(){
        return{
            files: [[], []],
            sidebarCollapsed: false,
        }
    },
    methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
        this.$router.push({ name: 'ExpertMode' });
        },
    uploadFile(){
        this.$router.push({ name: 'AnalyzePleadingResult' });
    }
    }
}
</script>

<style scoped>
.page-content{
  padding: 0;
}
.breadcrumb_header{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span{
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
</style>
<style scoped>

.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  h5{
    color: var(--Primary-Blue, #0E4485) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
.sub-title{
    color: var(--Neutral-Dark-Grey, #86888D);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
}
.right-container{
    height: 182px;
    width: 400px;
    display: flex;
padding: 0px 53px 0px 99px;
justify-content: flex-end;
align-items: center;
flex: 1 0 0;
border-radius: 8px;
background: var(--Neutral-Interface-Grey, #FAFBFC);
box-shadow: 0px 0px 4px 0px #F2F3F3;
p{
    color: var(--Neutral-Dark-Grey, #86888D) !important;

/* Small Paragraph */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 200% */
}
}

.toggle {
    display: flex;
    width: 40px;
  }.switch {
    flex-grow: 1;
    background: var(--Neutral-Mid-Grey, #d1d2d5);
    border-radius: 100px;
    padding: 2px;
    display: flex;
    align-items: center;}.button {
    width: 16px;
    height: 16px; background: var(--Neutral-White, #fff);
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
    
  }
  .swift:hover{
    background: rgba(24, 144, 255, 0.10);
    display: flex;
    border-radius: 4px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
.upload{border-radius: 4px;
background: rgba(14, 68, 133, 0.10);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--mid-grey);
}
.upload:hover{
    background: var(--primary);
}
</style>